// Monkey-patching of console methods - additionally generates events 'console_log' on window level
(function() {

  function patchConsoleMethod(methodName) {
    var originalMethod = console[methodName];
    if (!originalMethod) {
      return;
    }

    console[methodName] = function() {
      originalMethod.apply(console, arguments);

      try {
        var event = new CustomEvent(
          'console_log',
          {
            bubbles: true,
            detail: {
              methodName: methodName,
              arguments: arguments
            }
          });
        window.dispatchEvent(event);
      } catch (error) {
        // to avoid any issues with browser support
      }
    };
  }

  try {
    patchConsoleMethod('log');
    patchConsoleMethod('debug');
    patchConsoleMethod('trace');
    patchConsoleMethod('info');
    patchConsoleMethod('warn');
    patchConsoleMethod('error');
  } catch (error) {
    console.error('console-interceptor: ' + error);
  }
})();
